<template>
  <form @submit.prevent="handleSubmit">
    <slot name="header"></slot>

    <p>Upload assessment file for this permit application</p>
    <b-form-file v-model="fileCopy" required plain class="mb-4"></b-form-file>
    <p v-if="saved.filename" class="mt-2 mb-0">
      Uploaded: {{ saved.filename }}
    </p>

    <slot name="footer"></slot>
  </form>
</template>

<script>
  export default {
    name: 'AssessmentFileUpload',

    props: ['file', 'saved'],

    data () {
      return {
        fileCopy: this.file
      }
    },

    computed: {
      isAdmin () {
        return this.$store.state.user.role === 'admin'
      }
    },

    methods: {
      handleSubmit () {
        this.$emit('submitting', this.fileCopy)
      }
    }
  }
</script>

<style>
</style>
