<template>
  <div>
    <slot name="header"></slot>

    <div v-if="saved.url">
      <p>Your assessment file can be downloaded via this link</p>
      <a :href="saved.url" download>{{ saved.filename }}</a>
    </div>
    <p v-else>
      Your assessment file is being processed. Once finished, we'll provide you
      with a download link.
    </p>

    <slot name="footer"></slot>
  </div>
</template>

<script>
  export default {
    name: 'AssesmentFileCard',
    props: ['saved']
  }
</script>

<style>
</style>
