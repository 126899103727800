<template>
  <form @submit.prevent="handleSubmit">
    <slot name="header"></slot>

    <ul class="list-group list-group-flush mb-3">
      <li
        class="
          list-group-item
          d-flex
          justify-content-between
          px-0
          py-2
          border-bottom-0
        "
      >
        <p class="mb-0">Tax Due</p>
        <p class="mb-0">2.000</p>
      </li>
      <li
        class="
          list-group-item
          d-flex
          justify-content-between
          px-0
          py-1
          border-bottom-0
        "
      >
        <p class="mb-0">Status</p>
        <p class="mb-0">UNVERIFIED</p>
      </li>
    </ul>

    <div v-if="saved.url">
      <p class="mb-1">Payment receipt uploaded by the taxpayer:</p>
      <a :href="saved.url" download>{{ saved.filename }}</a>
    </div>
    <p v-else>The user hasn't uploaded the payment receipt, yet.</p>

    <slot name="footer"></slot>
  </form>
</template>

<script>
  // import Axios from 'axios'
  import PermitService from '@/services/PermitService'

  export default {
    name: 'PaymentFileCard',

    props: {
      saved: {
        type: Object,
        required: true
      }
    },

    methods: {
      async downloadFile () {
        await PermitService.downloadFile(this.file)
      },

      handleSubmit () {
        this.$emit('submitting', this.receiptCopy)
      }
    }
  }
</script>

<style>
</style>
