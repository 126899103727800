<template>
  <form @submit.prevent="handleSubmit">
    <slot name="header"></slot>

    <b-row>
      <b-col lg="6" v-for="doc in docInputs" :key="doc.type">
        <div class="mb-3">
          <p class="mb-0">
            <small>{{ doc.label }}</small>
          </p>
          <a v-if="uploaded(doc.type)" :href="getUrl(doc.type)" download>{{
            getName(doc.type)
          }}</a>
          <p v-else>The user has not uploaded the document yet.</p>
        </div>
      </b-col>
    </b-row>

    <slot name="footer"></slot>
  </form>
</template>

<script>
  export default {
    name: 'RequiredDocsCard',

    props: {
      savedDocs: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    data () {
      return {
        docInputs: [
          {
            label: 'DTI/SEC',
            type: 'dtisec'
          },
          {
            label: 'Lease Contract',
            type: 'lease'
          },
          {
            label: 'Brgy. Clearance for Business',
            type: 'clearance'
          },
          {
            label: 'Community Tax Certificate',
            type: 'taxcert'
          }
        ]
      }
    },

    watch: {
      docs: {
        deep: true,
        handler: 'copyDocs'
      }
    },

    methods: {
      copyDocs (newDocs) {
        this.docsCopy = { ...newDocs }
      },

      requestSubmit () {
        this.$el.requestSubmit()
      },

      submit () {
        this.$el.submit()
      },

      checkValidity () {
        return this.$el.checkValidity()
      },

      reportValidity () {
        return this.$el.reportValidity()
      },

      handleSubmit () {
        this.$emit('submitting', this.docsCopy)
      },

      uploaded (type) {
        const theDoc = this.savedDocs.find(doc => doc.type === type)
        return !!theDoc
      },

      getName (type) {
        const theDoc = this.savedDocs.find(doc => doc.type === type)
        return theDoc?.filename
      },

      getUrl (type) {
        const theDoc = this.savedDocs.find(doc => doc.type === type)
        return theDoc?.url
      },

      getLabel (type) {
        const theDoc = this.docInputs.find(doc => doc.type === type)
        return theDoc?.label
      }
    }
  }
</script>

<style>
</style>
