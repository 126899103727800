<template>
  <form @submit.prevent="handleSubmit">
    <h4 class="mb-4">Payment Receipt</h4>

    <ul class="list-group list-group-flush mb-3">
      <li
        class="
          list-group-item
          d-flex
          justify-content-between
          px-0
          py-2
          border-bottom-0
        "
      >
        <p class="mb-0">Tax Due</p>
        <p class="mb-0">2.000</p>
      </li>
      <li
        class="
          list-group-item
          d-flex
          justify-content-between
          px-0
          py-1
          border-bottom-0
        "
      >
        <p class="mb-0">Status</p>
        <p class="mb-0">UNVERIFIED</p>
      </li>
    </ul>

    <b-form-group>
      <label>Upload receipt to verify your tax receipt</label>
      <b-form-file required plain v-model="fileCopy"></b-form-file>
      <p v-if="saved.filename" class="mt-2 mb-0">
        Uploaded: {{ saved.filename }}
      </p>
    </b-form-group>

    <div class="d-flex justify-content-end">
      <b-button type="submit" variant="primary">Upload</b-button>
    </div>
  </form>
</template>

<script>
  export default {
    name: 'PaymentFileUpload',

    props: ['file', 'saved'],

    data () {
      return {
        fileCopy: this.file
      }
    },

    watch: {
      file: {
        deep: true,
        handler: 'copyFile'
      }
    },

    methods: {
      copyFile (newFile) {
        this.fileCopy = newFile
      },

      handleSubmit () {
        this.$emit('submitting', this.fileCopy)
      }
    }
  }
</script>

<style>
</style>
