<template>
  <form @submit.prevent="handleSubmit">
    <slot name="header"></slot>

    <b-form-group>
      <label>Upload mayor permit for this permit application.</label>
      <b-form-file required plain v-model="fileCopy"></b-form-file>
      <p v-if="saved.url" class="mt-2 mb-0">Uploaded: {{ saved.filename }}</p>
    </b-form-group>

    <slot name="footer"></slot>
  </form>
</template>

<script>
  export default {
    name: 'PermitFileUpload',
    props: ['file', 'saved'],

    data () {
      return {
        fileCopy: this.file
      }
    },

    watch: {
      file: {
        deep: true,
        handler: 'copyPermitFile'
      }
    },

    methods: {
      copyPermitFile (newPermitFile) {
        this.fileCopy = newPermitFile
      },

      handleSubmit () {
        this.$emit('submitting', this.fileCopy)
      }
    }
  }
</script>

<style>
</style>
