<template>
  <b-container>
    <h4 class="page-title">{{ $route.meta.title }}</h4>
    <b-row>
      <b-col>
        <b-card class="p-4 mb-4">
          <business-form-info
            :info="info"
            @submitting="saveBusiness"
            class="mb-4"
          ></business-form-info>
        </b-card>

        <b-card class="p-4 mb-4">
          <!-- Requirement Docs upload for taxpayer -->
          <required-docs-upload
            v-if="!isAdmin"
            :docs="docs"
            :saved="savedDocs"
            @submitting="uploadDocuments"
            class="mb-4"
          >
            <template #header>
              <h4 class="mb-4">Required Documents</h4>
            </template>
            <template #footer>
              <div class="d-flex justify-content-end">
                <b-button type="submit" variant="primary">Upload</b-button>
              </div>
            </template>
          </required-docs-upload>

          <!-- Required docs card for admin -->
          <required-docs-card v-if="isAdmin" :savedDocs="savedDocs">
            <template #header>
              <h4 class="mb-4">Required Documents</h4>
            </template>
          </required-docs-card>
        </b-card>

        <b-card class="p-4 mb-4">
          <!-- Assessment file upload for admin -->
          <assessment-file-upload
            v-if="isAdmin"
            :file="assessment"
            :saved="savedAssessment"
            @submitting="uploadAssessment"
          >
            <template #header>
              <h4 class="mb-4">Assessment File</h4>
            </template>
            <template #footer>
              <div class="d-flex justify-content-end">
                <b-button type="submit" variant="primary">Upload</b-button>
              </div>
            </template>
          </assessment-file-upload>

          <!-- Assessment file card for taxpayer -->
          <assessment-file-card v-if="!isAdmin" :saved="savedAssessment">
            <template #header>
              <h4 class="mb-4">Assessment File</h4>
            </template>
          </assessment-file-card>
        </b-card>

        <b-card class="p-4 mb-4">
          <!-- Payment receipt upload for taxpayer -->
          <payment-file-upload
            v-if="!isAdmin"
            :file="receipt"
            :saved="savedReceipt"
            @submitting="uploadReceipt"
          ></payment-file-upload>

          <!-- Payment receipt card for admin -->
          <payment-file-card v-if="isAdmin" :saved="savedReceipt">
            <template #header>
              <h4 class="mb-4">Payment Receipt</h4>
            </template>
          </payment-file-card>
        </b-card>

        <b-card class="p-4 mb-4">
          <!-- Permit file Upload for admin -->
          <permit-file-upload
            v-if="isAdmin"
            :file="mayorPermit"
            :saved="savedPermitFile"
            @submitting="uploadMayorPermit"
          >
            <template #header>
              <h4 class="mb-4">Mayor Permit</h4>
            </template>
            <template #footer>
              <div class="d-flex justify-content-end">
                <b-button type="submit" variant="primary">Upload</b-button>
              </div>
            </template>
          </permit-file-upload>

          <!-- Permit file card for user-->
          <permit-file-card v-if="!isAdmin" :saved="savedPermitFile">
            <template #header>
              <h4 class="mb-4">Mayor Permit</h4>
            </template>
          </permit-file-card>
        </b-card>
      </b-col>
    </b-row>

    <app-spinner ref="spinner"></app-spinner>
    <app-toast ref="toast"></app-toast>
  </b-container>
</template>

<script>
  import BusinessService from '@/services/BusinessService'
  import PermitService from '@/services/PermitService'
  import BusinessFormInfo from '@/components/BusinessFormInfo'
  import RequiredDocsUpload from '@/components/RequiredDocsUpload'
  import RequiredDocsCard from '@/components/RequiredDocsCard'
  import PaymentFileUpload from '@/components/PaymentFileUpload.vue'
  import PaymentFileCard from '@/components/PaymentFileCard.vue'
  import AssessmentFileUpload from '@/components/AssessmentFileUpload.vue'
  import AssessmentFileCard from '@/components/AssessmentFileCard.vue'
  import PermitFileCard from '@/components/PermitFileCard.vue'
  import PermitFileUpload from '@/components/PermitFileUpload.vue'

  export default {
    name: 'PermitEditor',

    props: ['id'],

    components: {
      BusinessFormInfo,
      RequiredDocsUpload,
      RequiredDocsCard,
      PaymentFileUpload,
      PaymentFileCard,
      AssessmentFileUpload,
      AssessmentFileCard,
      PermitFileCard,
      PermitFileUpload
    },

    data () {
      return {
        info: {
          userId: this.$store.state.user.id,
          name: '',
          tradeName: '',
          presidentFirstName: '',
          presidentLastName: '',
          presidentMi: '',
          subdivision: '',
          town: '',
          province: '',
          contactNo: '',
          email: '',
          ownershipKind: '',
          officeCategory: '',
          capitalInvestment: '',
          dtisec: '',
          dateRegistered: ''
        },
        docs: {
          dtisec: null,
          lease: null,
          clearance: null,
          taxcert: null
        },
        permit: {},
        receipt: null,
        assessment: null,
        mayorPermit: null,
        savedDocs: [],
        savedReceipt: {},
        savedAssessment: {},
        savedPermitFile: {}
      }
    },

    computed: {
      isAdmin () {
        return this.$store.state.user.role === 'admin'
      }
    },

    methods: {
      async loadPermit () {
        if (!this.id) return

        this.$refs.spinner.show()

        try {
          const {
            business,
            documents,
            receipt,
            assessment,
            mayorPermit,
            ...permit
          } = await PermitService.findById(this.id)

          this.info = business
          this.permit = permit

          this.savedDocs = documents
          this.savedAssessment = assessment ?? {}
          this.savedReceipt = receipt ?? {}
          this.savedPermitFile = mayorPermit ?? {}

          this.$refs.spinner.hide()
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      },

      async saveBusinessInfo (businessData) {
        const newBusiness = await BusinessService.save(businessData)
        return newBusiness
      },

      async saveBusinessPermit (data) {
        // Create a license for the business
        const expiredDate = new Date(new Date().getFullYear(), 11, 31)

        const newPermit = await PermitService.save({
          businessId: data.businessId,
          expiredAt: expiredDate.toISOString().substr(0, 10)
        })

        return newPermit
      },

      async saveBusiness (businessData) {
        // Show spinner
        this.$refs.spinner.show()

        try {
          // Make api call
          this.info = await this.saveBusinessInfo(businessData)
          this.permit = await this.saveBusinessPermit({
            businessId: this.info.id
          })

          // Hide spinner
          this.$refs.spinner.hide()

          // Show success notif
          this.$refs.toast.success('Business info saved successfully.')
        } catch (error) {
          // Hide spinner
          this.$refs.spinner.hide()

          // Show error notif
          this.$refs.toast.error(error.response.data.message)
        }
      },

      async uploadDocuments (docsData) {
        // Upload required documents for a particular permit
        const docUploads = new FormData()

        for (const prop in docsData) {
          docUploads.append(prop, docsData[prop])
        }

        this.$refs.spinner.show()

        try {
          this.savedDocs = await PermitService.saveDocuments(
            this.permit.id,
            docUploads
          )

          this.$refs.spinner.hide()
          this.$refs.toast.success('Required documents uploaded successfully.')
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      },

      async uploadReceipt (receipt) {
        // Prepare file upload
        const receiptUpload = new FormData()
        receiptUpload.append('receipt', receipt)

        // Show spinner
        this.$refs.spinner.show()

        try {
          this.savedReceipt = await PermitService.uploadReceipt(
            this.permit.id,
            receiptUpload
          )

          this.$refs.spinner.hide()
          this.$refs.toast.success('Payment receipt uploaded successfully.')
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      },

      async uploadAssessment (assessment) {
        // Prepare file upload
        const assessmentUpload = new FormData()
        assessmentUpload.append('assessment', assessment)

        // Show spinner
        this.$refs.spinner.show()

        try {
          const savedAssessment = await PermitService.uploadAssessment(
            this.permit.id,
            assessmentUpload
          )

          this.savedAssessment = savedAssessment.filename

          this.$refs.spinner.hide()
          this.$refs.toast.success('Assessment file uploaded successfully.')
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      },

      async uploadMayorPermit (permitFile) {
        // Prepare file upload
        const permitFileUpload = new FormData()
        permitFileUpload.append('permitFile', permitFile)

        // Show spinner
        this.$refs.spinner.show()

        try {
          this.savedPermitFile = await PermitService.uploadMayorPermit(
            this.permit.id,
            permitFileUpload
          )

          this.$refs.spinner.hide()
          this.$refs.toast.success('Mayor Permit file uploaded successfully.')
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      }
    },

    mounted () {
      this.loadPermit()
    }
  }
</script>

<style>
</style>
